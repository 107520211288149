<template>
<div>
  <ResetMasukkanOtpPassword></ResetMasukkanOtpPassword>
</div>
</template>

<script>


import ResetMasukkanOtpPassword from "@/components/reset-password/ResetMasukanOtpPassword.vue";

export default {
  name: "RegistrationMasukanOtpView",
  components: {
    ResetMasukkanOtpPassword
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>

</style>
