<template>
  <div class="bg">
    <v-form v-model="valid" ref="form" class="pt-16">
      <v-row class="justify-center">
        <v-col cols="12" md="6" sm="8">
          <v-card elevation="1" class="mx-auto rounded-lg">
            <v-row class="justify-center">
              <v-col cols="12" md="10" sm="10" class="pb-16 px-8">
                <div class="text-center mt-2">
                  <v-icon x-large color="green">mdi-whatsapp</v-icon>
                </div>
                <div class="d-flex flex-column mt-4 font-weight-bold subtitle-1 text-center">
                  RESET PASSWORD
                </div>
                <v-divider></v-divider>
                <div class="mt-4 mx-4 subtitle-2 font-weight-light text-center">
                  Verification code has been sent to your {{ itemFtReg.lastName }}'s  email/WhatsApp number.
                </div>

                <div class="otp-container mt-4" v-if="! otpValid">
                  <input type="text"
                         v-model="user.otp"
                         @input="validateOtp"
                         class="otp-input"
                         :disabled="counter <= 0"
                         maxlength="6" placeholder="Enter OTP">
                </div>


                <div class="pwd-container mt-8 flex-column" v-if="otpValid">
                  <div class="pwd-wrapper">
                    <input :type="showPassword ? 'text' : 'password'"
                           v-model="user.password"
                           class="pwd-input"
                           placeholder="Your New Password">
                    <v-icon @click="togglePasswordVisibility" class="eye-icon">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </div>
                  <div class="pwd-wrapper mt-4">
                    <input :type="showRetypePassword ? 'text' : 'password'"
                           v-model="user.retypePassword"
                           class="pwd-input"
                           placeholder="Retype Password">
                    <v-icon @click="toggleRetypePasswordVisibility" class="eye-icon">{{ showRetypePassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </div>
                  <div v-if="user.retypePassword">
                    <div v-if="!isMatchPassword && user.retypePassword.length>2" class="caption red--text">
                      Password not match
                    </div>
                  </div>
                </div>

                <div class="text-center grey--text mt-5 subtitle-2">
                  Time left: {{ counter }} seconds
                </div>


                <div class="text-center mt-4">
                  <v-btn
                      v-if="otpValid"
                      :disabled="! isMatchPassword "
                      color="primary" class="rounded-lg"
                      @click="handChangePassword" width="200px">
                    Change Password
                  </v-btn>
                </div>

                <div class="mt-8"></div>
                <div class="text-center" v-if="errorMessage">
                  <span class="red--text">
                    {{ errorMessage}}.
                  </span>
                  <span>
                    <router-link :to="{ path: '/reset-atur-ulang'}" color="white" plain class="blue--text" style="text-transform: none !important; text-decoration: none">
                      Try Again?
                    </router-link>
                  </span>
                </div>
                <v-divider></v-divider>
                <div class="text-center grey--text mt-2 subtitle-2">
                  BKPSDM KOTA PALANGKARAYA 2024
                </div>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import User from "@/models/user";
import FtRegistrationRegService from "@/services/apiservices-account-registration/ft-registration-reg-service";

export default {
  name: "ResetMasukkanOtpPassword",
  components: {},
  data() {
    return {
      otpValid: false,
      showPassword: false,
      showRetypePassword: false,

      valid: false,
      rulesMinLength: (v) => {
        return (v && v.length >=5) || "Must be at least 5 characters long";
      },
      errorMessage: "",
      user: new User("", ""),
      counter: 200,

    };
  },
  computed: {
    isMatchPassword() {
      return ((this.user.password === this.user.retypePassword) && this.user.password);
    },
    itemFtReg(){
      return this.$store.state.registration.itemFtReg
    }
  },
  created() {
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleRetypePasswordVisibility() {
      this.showRetypePassword = !this.showRetypePassword;
    },
    
    validateOtp() {
      if (this.user.otp.length === 5 &&  this.counter > 0) {
        let newPassword = "xx"
        this.startValidate(newPassword);
      }
    },

    handChangePassword(){
      if (this.user.otp.length === 5 &&  this.counter > 0) {
        let newPassword = "xxx"
        if (this.user.password){
          newPassword = this.user.password;
        }
        this.startValidate(newPassword);
      }

    },

    startValidate(newPassword){

      FtRegistrationRegService.resetValidateOtpPasswordPublic(this.itemFtReg.ref, this.user.otp, newPassword)
          .then((response) => {
            if (response.status === 200 && response.data.status.includes('otp valid but empty password')) {
              this.otpValid = true;

              this.$store.dispatch('registration/setItemFtReg', response.data).then(() => {

                this.setCounter();
                this.startCountdown();

              });

            }else if (response.status === 200 && response.data.status.includes('otp valid and password valid')) {
              this.$router.push("/reset-success");
            }

          })
          .catch((error) => {
            console.log(JSON.stringify(error.response, null, 2));

          });
    },


    startCountdown() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
          this.errorMessage = "Session is expired";
        }
      }, 1000);
    },

    formatToThreeDigits(time) {
      const isoString = typeof time === 'string' ? time : time.toISOString();
      const [date, timePart] = isoString.split('T');
      const [timeComponent, offset] = timePart.split(/[+-]/);
      const formattedTimeComponent = timeComponent.length > 8 ? timeComponent.slice(0, 8) + '.' + timeComponent.slice(9, 12) : timeComponent;
      return `${date}T${formattedTimeComponent}${offset ? isoString.slice(isoString.indexOf(offset) - 1) : ''}`;
    },
    toISOWithOffset(date) {
      const tzo = -date.getTimezoneOffset(),
          dif = tzo >= 0 ? '+' : '-',
          pad = function(num) {
            return (num < 10 ? '0' : '') + num;
          };

      return date.getFullYear() +
          '-' + pad(date.getMonth() + 1) +
          '-' + pad(date.getDate()) +
          'T' + pad(date.getHours()) +
          ':' + pad(date.getMinutes()) +
          ':' + pad(date.getSeconds()) +
          '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
          dif + pad(Math.floor(Math.abs(tzo) / 60)) +
          ':' + pad(Math.abs(tzo) % 60);
    },

    setCounter(){
      const start = this.toISOWithOffset(new Date());
      const timeEnd = this.toISOWithOffset(new Date(this.itemFtReg.timeEnd));

      const formattedStart = this.formatToThreeDigits(start);
      const formattedTimeEnd = this.formatToThreeDigits(timeEnd);

      const dateStart = new Date(formattedStart);
      const dateEnd = new Date(formattedTimeEnd);

      // Hitung selisih dalam milidetik
      this.diffInMilliseconds = dateEnd - dateStart;

      if (this.diffInMilliseconds <= 0) {
        this.counter = 0
      }else {
        this.counter = Math.round(this.diffInMilliseconds/1000);
      }

    }

  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {

    this.setCounter();
    this.startCountdown();

  },
};
</script>

<style scoped>

.bg {
  background: #edf1f3;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  background-color: #f0f8ff;
}

.otp-input {
  width: 300px;
  padding: 10px 15px;
  font-size: 18px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-align: center;
  letter-spacing: 2px;
}

.otp-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.pwd-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  background-color: #f0f8ff;
  flex-direction: column;
}
.pwd-input {
  width: 300px;
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-align: center;
  letter-spacing: 2px;
}
.pwd-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.eye-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

</style>


